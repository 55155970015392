exports.PROD_SPECS = [
  {
    title: 'Individual',
    subtitle: 'Free',
    features: [
      'Single User',
      '5 Report Templates',
      'Shared Domain Name',
      'Two Months of Data Retention',
    ],
    buttonText: 'Subscribe',
    tier: 'free',
    unit_amount: 0,
    disabled: true,
  },
  {
    title: 'Starter',
    subtitle: '$1/user/month',
    features: [
      '10 Report Templates',
      'Dedicated Domain Name',
      'Role Based Access Permission',
      'Standard Report Workflow',
      'Six Months of Data Retention',
    ],
    buttonText: 'Subscribe',
    tier: 'starter_monthly',
    unit_amount: 100,
  },
  {
    title: 'Business',
    subtitle: '$5/user/month',
    features: [
      '25 Report Templates',
      'Dedicated Domain Name',
      'Role Based Access Permission',
      'Standard and Customizable Report Workflow',
      'Two years of Data Retention',
    ],
    buttonText: 'Subscribe',
    borderHighlight: true,
    tier: 'business_monthly',
    unit_amount: 500,
  },
  {
    title: 'Enterprise',
    subtitle: '$25/user/month',
    features: [
      'Unlimited Report Templates',
      'Dedicated Domain Name',
      'Role Based Access Permission + Fine Grained Access Permission',
      'Standard and Customizable Report Workflow',
      'Unlimited Data Retention',
    ],
    buttonText: 'Subscribe',
    tier: 'enterprise_monthly',
    unit_amount: 2500,
  },
];
