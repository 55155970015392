import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const SubscribeModalDialog = ({
  heading,
  onYes,
  onNo,
  showModal,
  appContext,
  setAppContext,
}) => {
  const [validated, setValidated] = useState(false);

  const onNumUsersChange = async (e) => {
    const backspaceOrDelete = e.target.value === '';
    const num = parseInt(e.target.value);

    //only numbers and backspaceOrDelete allowed
    if (!isNaN(num) || backspaceOrDelete) {
      if (num > 999) return;
      const val = e.target.value;
      setAppContext({
        ...appContext,
        numUsers: val,
      });
    }
  };

  const subscribeConfirm = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget.form;
    if (form.checkValidity() === false) {
      setValidated(true);
      return;
    }
    onYes();
  };

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showModal}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          noValidate
          validated={validated}
          onSubmit={onYes}
          id="subModalForm"
          name="subModalForm"
        >
          <Form.Group as={Row} className="mb-3" controlId="numUsers">
            <Form.Label column sm="7">
              Number of Users for teamreports.io <br />
              <span style={{ fontSize: '0.8rem' }}>
                (including admin and each stakeholder)
              </span>
            </Form.Label>
            <Col sm="2">
              <Form.Control
                type="text"
                required
                pattern={'[1-9]{1,3}'}
                value={appContext?.numUsers}
                onChange={(e) => onNumUsersChange(e)}
                autoComplete="off"
                name="numUsersControl"
              />
              <span style={{ fontSize: '0.7rem' }}>(1-999)</span>
              <Form.Control.Feedback type="invalid">
                *Required
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '20px',
            }}
          >
            <Button
              variant="outline-secondary"
              onClick={subscribeConfirm}
              style={{ marginRight: '20px' }}
            >
              Subscribe
            </Button>
            <Button variant="outline-secondary" onClick={onNo}>
              Later
            </Button>
          </div>
        </Form>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            fontSize: '0.8rem',
            paddingTop: '10px',
            marginLeft: '200px',
            marginRight: '200px',
          }}
        >
          Subscribe button will take you to Stripe website for secure processing
          of payment. teamreports.io does not store your financial or credit
          card information.
        </div>
      </Modal.Body>
      <Modal.Footer />
    </Modal>
  );
};

export default SubscribeModalDialog;
