import Head from 'next/head';
import styles from '../styles/Home.module.css';
import {
  getCreateStripeSessionApiAuthHeader,
  readSSMParameter,
} from '../lib/init';
import { PROD_SPECS } from '../lib/prod-specs';
import axios from 'axios';
import { useState } from 'react';
import SubscribeModalDialog from '../comps/SubscribeModalDialog';
import { loadStripe } from '@stripe/stripe-js';
import { Alert, Col, Row } from 'react-bootstrap';
import InfoModal from '../comps/InfoModal';
import PricingCard from '../comps/PricingCard';
import { Template } from '@styled-icons/heroicons-outline';
import { PeopleTeam } from '@styled-icons/fluentui-system-filled';
import { Elastic } from '@styled-icons/simple-icons';
import { Workflow } from '@styled-icons/octicons';
import { Security } from '@styled-icons/material-rounded';
import { EmojiNature } from '@styled-icons/material';
import TrepHeader from '../comps/TrepHeader';
import Router from 'next/router';
import Image from 'next/image';
import YouTube from 'react-youtube';
import Link from 'next/link';
import SimpleMenu from '../comps/SimpleMenu';

let CREATE_STRIPE_SESSION_AUTH_HEADER = undefined;
let STRIPE_PUB_KEY = undefined;

export async function getStaticProps() {
  try {
    if (!CREATE_STRIPE_SESSION_AUTH_HEADER) {
      CREATE_STRIPE_SESSION_AUTH_HEADER =
        await getCreateStripeSessionApiAuthHeader();
    }
    if (!STRIPE_PUB_KEY) {
      STRIPE_PUB_KEY = await readSSMParameter(
        `/trep/${process.env.Environment}/stripe/pubkey`
      );
    }
    const isSubscriptionEnabled = await readSSMParameter(
      `/trep/${process.env.Environment}/subscription/enabled`
    );
    console.log(`subscription enabled:${isSubscriptionEnabled}`);
    return {
      props: {
        isSubscriptionEnabled: isSubscriptionEnabled === 'true' ? true : false,
        stripe: {
          pubKey: STRIPE_PUB_KEY,
          authHeader: CREATE_STRIPE_SESSION_AUTH_HEADER,
        },
      },
    };
  } catch (err) {
    console.log(err);
  }
}

export default function Home({
  isSubscriptionEnabled,
  stripe,
  appContext,
  setAppContext,
}) {
  const [showSubDialog, setShowSubDialog] = useState(false);
  const [showDangerAlert, setShowDangerAlert] = useState(false);
  const [infoModalContext, setInfoModalContext] = useState({});
  const [showInfoModal, setShowInfoModal] = useState(false);

  const subscribeRequest = async (tier) => {
    setAppContext({
      ...appContext,
      tier,
    });
    // setShowSubDialog(true);
    Router.push('/onboard');
  };

  const subscribeConfirm = async () => {
    await createCheckOutSession();
  };

  const createCheckOutSession = async () => {
    const stripePromise = loadStripe(stripe.pubKey);
    const stripeInstance = await stripePromise;

    if (!appContext?.numUsers) {
      throw 'Number of users is not defined';
    }
    try {
      setShowInfoModal(true);
      setInfoModalContext({
        heading: 'Info',
        msg: 'Contacting Stripe...',
      });
      const checkoutSession = await axios.post(
        '/api/create-stripe-session',
        {
          quantity: appContext.numUsers,
          tier: appContext.tier,
        },
        {
          headers: {
            Authorization: `Bearer ${stripe.authHeader}`,
          },
        }
      );
      setAppContext({
        ...appContext,
        sessionId: checkoutSession.data.id,
      });

      setInfoModalContext({
        heading: 'Info',
        msg: 'Waiting for Stripe redirect...',
      });

      await stripeInstance.redirectToCheckout({
        sessionId: checkoutSession.data.id,
      });
    } catch (err) {
      setShowDangerAlert(true);
    }
  };

  const opts = {
    height: '300',
    width: '300',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
    },
  };

  function _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  return (
    <div className={styles.container}>
      <Head>
        <title>teamreports.io</title>
        <meta name="description" content="Build Transparency" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <TrepHeader />
      <br />
      <main className={styles.main}>
        <SimpleMenu />
        <Alert
          variant="danger"
          show={showDangerAlert}
          onClose={() => setShowDangerAlert(false)}
          dismissible
        >
          <Alert.Heading>Something went wrong!</Alert.Heading>
          <p>Please contact support@teamreports.io</p>
        </Alert>

        <InfoModal
          heading={infoModalContext?.heading}
          msg={infoModalContext?.msg}
          showModal={showInfoModal}
        />

        <SubscribeModalDialog
          showModal={showSubDialog}
          heading={'Confirm Subscription'}
          onNo={() => setShowSubDialog(false)}
          onYes={() => {
            subscribeConfirm();
            setShowSubDialog(false);
          }}
          appContext={appContext}
          setAppContext={setAppContext}
        />

        <p className={styles.description} style={{ color: '#375361' }}>
          Automate and streamline the process of collecting information from the
          team and sharing with the Stakeholders
        </p>

        <div>
          <Row>
            <Col
              style={{
                display: 'flex',
                justifyContent: 'center',
                paddingBottom: '10px',
              }}
            >
              <h2>Stakeholders Reporting Process</h2>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <h3 style={{ display: 'flex', justifyContent: 'center' }}>
                Conventional way
              </h3>
              <br />
              <Image
                src="/info-trep-before.webp"
                priority
                width={650}
                height={500}
              />
            </Col>
            <Col>
              <h3 style={{ display: 'flex', justifyContent: 'center' }}>
                With teamreports
              </h3>
              <br />
              <Image
                src="/info-trep-after.webp"
                priority
                width={650}
                height={500}
              />
            </Col>
          </Row>
        </div>
        <p
          className={styles.description}
          style={{
            display: 'flex',
            justifyContent: 'center',
            maxWidth: '1000px',
          }}
        >
          <span style={{ color: '#5F9EA0' }}>team</span>
          <span style={{ color: '#6B8E23' }}>reports</span>
          <span style={{ color: '#5F9EA0' }}>.i</span>
          <span style={{ color: '#6B8E23' }}>o</span>
          <span>
            empowers teams in building reports collaboratively eliminating the
            manual efforts of collating the information and emailing
            stakeholders{' '}
          </span>
        </p>
        <a id="features">
          <p className={styles.subtitle}>Features</p>
        </a>

        <div className={styles.grid}>
          <a className={styles.card}>
            <h2>
              <Template size="35" title="Author" /> Templates
            </h2>
            <p>Configurable Report Templates supporting variety of use cases</p>
          </a>

          <a className={styles.card}>
            <h2>
              <PeopleTeam size="35" title="collaborate" /> Collaboration
            </h2>
            <p>
              Collaborative Report Generation with well-defined responsibilities
            </p>
          </a>

          <a className={styles.card}>
            <h2>
              <Elastic size="35" title="Flexibility" /> Flexible
            </h2>
            <p>Adhoc, Recurring Reports, Performance Appraisal and many more</p>
          </a>

          <a className={styles.card}>
            <h2>
              <Workflow size="35" title="Workflow" /> Workflow
            </h2>
            <p>
              Customizable Workflow driven Reporting process with feedback loop
            </p>
          </a>

          <a className={styles.card}>
            <h2>
              <Security size="35" title="Group" /> Security
            </h2>
            <p>Role based and Report specific access permissions</p>
          </a>

          <a className={styles.card}>
            <h2>
              <EmojiNature size="35" title="Sustainability" /> Sustainable
            </h2>
            <p>Low carbon footprint. 100% Serverless with 80% Functionless</p>
          </a>
        </div>

        <a id="pricing">
          <p className={styles.subtitle}>Pricing</p>
        </a>

        <div className={styles.grid} style={{ maxWidth: '1400px' }}>
          {PROD_SPECS.map((prod, index) => (
            <PricingCard
              key={`prod-${index}`}
              {...prod}
              isSubscriptionEnabled={isSubscriptionEnabled && !prod?.disabled}
              buttonClicked={(tier) => subscribeRequest(tier)}
            />
          ))}
        </div>

        <a id="videos">
          <p className={styles.subtitle}>How to Videos</p>
        </a>

        <div className={styles.videos}>
          {/* subscription */}
          <div style={{ marginRight: '30px' }} className={styles.viditem}>
            <YouTube videoId="InVGlWYissw" opts={opts} onReady={_onReady} />
          </div>
          {/* admin user sign in */}
          <div style={{ marginRight: '30px' }} className={styles.viditem}>
            <YouTube videoId="3_1JPjsWw2I" opts={opts} onReady={_onReady} />
          </div>
          {/* user sign up */}
          <div style={{ marginRight: '30px' }} className={styles.viditem}>
            <YouTube videoId="HqhXAMxmsGQ" opts={opts} onReady={_onReady} />
          </div>
          {/* assign roles */}
          <div style={{ marginRight: '30px' }} className={styles.viditem}>
            <YouTube videoId="VuCOENiV2ZI" opts={opts} onReady={_onReady} />
          </div>
          {/* author templates */}
          <div style={{ marginRight: '30px' }} className={styles.viditem}>
            <YouTube videoId="lHSTDw3tY24" opts={opts} onReady={_onReady} />
          </div>
        </div>
      </main>

      <footer className={styles.footer}>reachteamreports@gmail.com</footer>
    </div>
  );
}
