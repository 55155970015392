import React from 'react';
import { Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

const InfoModal = ({
  heading,
  msg,
  buttonText,
  onButtonClick,
  showModal,
  showButtons,
}) => {
  return (
    <Modal
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showModal}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">{heading}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{msg}</p>
      </Modal.Body>
      {showButtons && (
        <Modal.Footer>
          <Button onClick={onButtonClick} variant="outline-secondary">
            {buttonText ? buttonText : 'Ok'}
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default InfoModal;
